import React from 'react'
import { Link } from 'gatsby'

const CTA = () => {
    return (
        <section className="cta py-60">
            <div className="container">
                <div className="cta-flex">
                    <div className="cta-text">
                        <h2>Ready to work with us?</h2>
                        <h2 className="text-red">Connect with an expert today.</h2>
                    </div>
                    <div className="cta-buttons">
                        <Link to='/contact' className="button button-red">Contact us</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA