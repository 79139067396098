import React from 'react'
import { Link } from 'gatsby' 
import Layout from '../layouts/simple.js'
import '../styles/about.scss'
import Team from '../images/team-photo.jpg'
import CTA from '../components/cta.js'

const About = () => {
    return (
        <Layout>
            <section className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>Everyone Should Live in Style and Comfort</h1>
                            <h3>For 100 years we've made problem-solving designs that are as beautiful as they are useful.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section id="timeline" className="py-60">
                <div className="container column-one-two">
                    <div className="timeline-about">
                        <div className="sticky top-60">
                            <h2>Celebrating 100 Years</h2>
                            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad.</h3>
                        </div>
                    </div>
                    <div className="timeline">
                        <div class="timeline-container right">
                            <div class="timeline-content">
                            <h4>1921</h4>
                            <p>Dundas Office Interiors Opens on Noth Main in Helena.</p>
                            </div>
                        </div>
                        <div class="timeline-container right">
                            <div class="timeline-content">
                            <h4>1923</h4>
                            <p>Herman Miller Name Brand Begins.</p>
                            </div>
                        </div>
                        <div class="timeline-container left">
                            <div class="timeline-content">
                            <h4>1927</h4>
                            <p>Babe Ruth hits 60 home runs.</p>
                            </div>
                        </div>
                        <div class="timeline-container right">
                            <div class="timeline-content">
                            <h4>1928</h4>
                            <p>Dundas Opens on Central Avenue in Great Falls.</p>
                            </div>
                        </div>
                        <div class="timeline-container left">
                            <div class="timeline-content">
                            <h4>1936</h4>
                            <p>Hoover Dam Built.</p>
                            </div>
                        </div>
                        <div class="timeline-container left">
                            <div class="timeline-content">
                            <h4>1944</h4>
                            <p>Ball Point Pen Introduced.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="team" className="py-60">
                <div className="container">
                    <h2 className="text-center">The Dundas Interiors Family</h2>
                    <h3 className="text-center mw-800 centered">Whether by blood or by choice, everyone at Dundas Interiors is family. We believe our people are the reason we've been in business 100 years and we couldn't have done it without them.</h3>
                    <img src={Team} alt="" />
                </div>
            </section>
            <CTA />
        </Layout>
    )
}

export default About